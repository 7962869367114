import * as firebase from "firebase";

import {connect, reduce, getState, useDispatch} from 'react-redux';
import { showMessage, hideMessage } from "react-native-flash-message";

const selectBranch = (data) => {

  global.branchId = data.id

  return {
    type: "SELECT_BRANCH",
    value: data,
  }
}


const changeBranch = () => {

  global.branchId = null

  return {
    type: "CHANGE_BRANCH"
  }
}

export { selectBranch, changeBranch }
