
import { createStore, applyMiddleware, useSelector } from 'redux';
import thunkMiddleware from 'redux-thunk';

import * as firebase from 'firebase';
import 'firebase/firestore';
// Set Orders Data

const setOrdersData = (ordersData) => {
  return {
    type: "SET_ORDERS_DATA",
    value: ordersData,
  }
};

const watchOrdersData = (ordersData, company) => {
  var user = firebase.auth().currentUser;

  if (user) {
  return function(dispatch) {
    global.backendFirebase.firestore()
        .collection("/orders/" + global.companyId + "/order/")
        .where("customer.id", "==", global.customerId)
        .where("isPaid", "in", [0, 1])
        .orderBy("timeCreated", "desc")
        .onSnapshot(querySnapshot => {
          const data = querySnapshot.docs.map(doc => {
            const id = doc.id
            const data = doc.data()
            return Object.assign({id, ...data})
          });
          var actionSetOrdersData = setOrdersData(data);
          dispatch(actionSetOrdersData);
        });
  }} else {
    return function(dispatch) {
      var actionSetOrdersData = setOrdersData("");
      dispatch(actionSetOrdersData)
    }
  }
};

const setOrdersDetailsData = (ordersDetailData) => {
  return {
    type: "SET_ORDER_DETAILS_DATA",
    value: ordersDetailData,
  }
};

const watchOrdersDetailsData = (ordersDetailData, company) => {

  var user = firebase.auth().currentUser;

  if (user) {
  return function(dispatch) {
    global.backendFirebase.firestore()
        .collection("/orders/" + global.companyId + "/orderData/")
        .where("customer.id", "==", global.customerId)
        .onSnapshot(querySnapshot => {
          const data = querySnapshot.docs.map(doc => {
            const id = doc.id
            const data = doc.data()
            return Object.assign({id, ...data})
          });
          var actionSetOrdersDetailsData = setOrdersDetailsData(data);
          dispatch(actionSetOrdersDetailsData);
        });
  }} else {
    return function(dispatch) {
      var actionSetOrdersDetailsData = setOrdersDetailsData("");
      dispatch(actionSetOrdersDetailsData)
    }
  }
};



export {setOrdersData, watchOrdersData, setOrdersDetailsData, watchOrdersDetailsData};
