
import { createStore, applyMiddleware, useSelector } from 'redux';
import thunkMiddleware from 'redux-thunk';

import * as firebase from 'firebase';
import 'firebase/firestore';

// Set Orders Data

const setBranchesData = (branchesData) => {
  return {
    type: "SET_BRANCHES_DATA",
    value: branchesData,
  }
};

const watchBranchesData = (branchesData, company) => {

  return function(dispatch) {
    global.backendFirebase.firestore()
        .collection("/branches/" + global.companyId + "/branch/")
        .where("isOnline", "==", true)
        .orderBy("location", "asc")
        .onSnapshot(querySnapshot => {
          const data = querySnapshot.docs.map(doc => {
            const id = doc.id
            const data = doc.data()
            return Object.assign({id, ...data})
          });
          var actionSetBranchesData = setBranchesData(data);
          dispatch(actionSetBranchesData);
        });
  }
};

const setBranchesHoursData = (branchesData) => {
  return {
    type: "SET_BRANCHES_HOURS_DATA",
    value: branchesData,
  }
};

const watchBranchesHoursData = (branchesData, company) => {

  return function(dispatch) {
    global.backendFirebase.firestore()
        .collection("/branches/" + global.companyId + "/hours/")
        .onSnapshot(querySnapshot => {
          const data = querySnapshot.docs.map(doc => {
            const id = doc.id
            const data = doc.data()
            return Object.assign({id, ...data})
          });
          var actionSetBranchesHoursData = setBranchesHoursData(data);
          dispatch(actionSetBranchesHoursData);
        });
  }
};

export {setBranchesData, watchBranchesData, setBranchesHoursData, watchBranchesHoursData};
