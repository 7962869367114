import React from "react";
import hero1 from "../../assets/cover.png";
import "../styles.css";


function Carousel() {
  return (
    <>
      <div
        id="carouselExampleAutoplaying"
        class="carousel slide"
        data-bs-ride="carousel"
      >
      <div class="carousel-inner">
        <div class="carousel-item active">
          
        <img src={hero1} class="cImg d-block w-100" alt="Family Fish and Chips Cover Image" />

          <div class="carousel-caption d-md-flex justify-content-center align-items-center">
            <div class="text-center">
              <h1 class="hero-heading">Fresh Catch, Happy Family</h1>

              <div class="hero-buttons row d-none d-md-flex justify-content-center">
                <div class="col-md-auto">
                  <a href="/takeaway" class="hero-link" alt="Link to Order Food">
                    <button type="button" class="hero-button">Order Online</button>
                  </a>
                </div>

              </div>

              <div class="d-md-none">

                <a href="/takeaway" class="hero-link" alt="Link to Order Food">
                  <button type="button" class="hero-button">Order Online</button>
                </a>

              </div>
              </div>
            
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default Carousel;
