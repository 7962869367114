import React from 'react';

import { StyleSheet, Text, View, ScrollView, ActivityIndicator } from 'react-native';
import { colors } from './../constants/colors.js';
import { fonts } from './../constants/fonts.js';

export default class PrivacyScreen extends React.Component {

  render() {
    return (
      <View style={styles.main}>

          <View style={styles.header}>
            <View style={[styles.container, {paddingHorizontal: global.windowWidth > 768 ? 30 : 0} ]}>
              <Text style={{color: colors.secondary, fontWeight: "700", fontSize: 24}}>Privacy Policy</Text>
            </View>
          </View>

        <ScrollView style={styles.container}>

          <View style={{paddingHorizontal: global.windowWidth > 768 ? 30 : 0}}>

<Text style={styles.h1}>Introduction</Text>

<Text style={styles.p}>
At Fyre we are committed protecting the privacy of everyone in our community. This means that we’ll protect the personal information of all visitors who access our websites or services through any mobile application, platform or device (collectively, the “Services”).This privacy notice (“Privacy Notice”) explains how Fyre, our subsidiaries and affiliates collect, share, and use your personal information. You’ll also find information about how you can exercise your privacy rights. By using our Services you agree to Fyre using your personal information as described in this Privacy Notice. The terms “we”, “us” or “our” are each intended as reference to ‘Fyre’ and any terms not defined in this Privacy Notice are defined in our Terms.
</Text>

<Text style={styles.h1}>Summary</Text>
<Text style={styles.p}>
We’ve summarised the key points of our Privacy Notice below. If you want more information on a particular section, just click on the Learn more links below to jump to that section.
1. Information we collect from you and why
From time to time, we may receive personal information from third party sources, including advertising networks and social media platforms like Facebook but only where we have checked that these third parties either have your consent to process the information or are otherwise legally permitted or required to share your personal information with us. This information may include your likely demographic group.
A. Information that you provide voluntarily:
Registration information when you create a Fyre account so we can:
- create your account so you can place Orders under our Terms
- identify you when you sign-in to your account;
- contact you for your views on our Services; and
- notify you of changes or updates to our Services.
Transaction Information when you place an Order with a Restaurant in order to:
- process your Order and to bill you (though note, Fyre never stores your credit card information in our systems);
- communicate your Order to the Restaurant;
- send you status updates on your Order;
- reply to your Order queries and questions and to resolve problems;
- carry out analysis and research to develop and improve our Services; and
- protect you and the Services by seeking to detect and prevent fraud or other acts in breach of our Terms or policies relevant to the Services.
Information regarding your marketing preferences so we can:
- send you customised marketing about our products or services.
Feedback on your views of our Services in order to:
- reply to your questions or queries;
- publish reviews you submit to us about our Restaurants and services; and
- conduct analysis and research to improve and develop our Services.
</Text>

<Text style={styles.h2}>B. Information that we collect automatically</Text>
<Text style={styles.p}>
Activity Information so we can:
- provide you with an improved experience;
- give you access to your Order history and preferences; and
- provide other services at your request.
Cookies and similar technologies so we can:
- measure and analyse the use and effectiveness of our Services;
- customise and optimise the targeting of advertising for our Services across other websites and platforms; and
- provide location services if you choose to share your geo-location.
C. Information that we obtain from third-party sources
Analytics reports and market research surveys in order to:
- measure the performance of marketing campaigns for our Services; and
- better understand your preferences so that we can customise our marketing campaigns and Services accordingly.
We process information that you provide voluntarily, information that we collect automatically and information that we obtain from third party sources in order to constantly improve our Services. Using this, we can make it even easier for you to find the food you want when you want it, no matter what device you choose to use or where you are in the world.
</Text>

<Text style={styles.h2}>2. Who we share personal information with</Text>

<Text style={styles.p}>
We may share your personal information with the following recipients (and whenever we do, we will ensure that we have appropriate security and contractual safeguards in place to protect it):
Restaurants that you have placed an Order with for processing and delivery;
Fyre companies within our group;
Third parties who undertake various activities to promote, market or support our Services. This includes social media platforms like Facebook, offshore customer support agents, website and application support and hosting providers, marketing service providers, eCRM partners like Salesforce who manage our marketing emails and push notifications, companies like Twilio who send you text messages when your Order is on its way, delivery companies who deliver your Order to you, market research companies and payment processing providers which process payment card transactions - any of these third parties might be inside or outside your country of residence;
Any law enforcement or regulatory body, government agency, court or other third party where we believe disclosure is necessary under applicable law or regulations;
New owners or re-organised entities in the event of a business restructuring, sale, purchase or joint venture affecting our business.
Any other person provided that you have given your consent. We will never sell, distribute or otherwise share your personal information unless we have your permission.
</Text>

<Text style={styles.h2}>3. Legal basis for processing personal information</Text>
<Text style={styles.p}>
Fyre will always make sure we have a legal basis to collect and use your personal information. The legal basis we rely on will change depending on the type of information and the context in which we collect it.
Our main reason for collecting and using your personal information is to perform our contract with you (i.e. to make sure you get your Order when and where you want it), but we may also process it where it is in our legitimate business interests to do so.
</Text>

<Text style={styles.h2}>4. International data transfers</Text>

<Text style={styles.p}>We may transfer your personal information to countries outside the one in which you are resident to other countries where Fyre or our service providers have operations. The data protection laws of these countries may differ from the laws of your country but Fyre takes care to implement appropriate safeguards to protect your personal information in those countries in accordance with this Privacy Policy.
</Text>

<Text style={styles.h2}>5. Security</Text>
<Text style={styles.p}>Fyre places great importance on protecting your information from and against unauthorised access and against unlawful processing, accidental loss, destruction and damage. We implement appropriate technical and organisational measures to safeguard such information.
</Text>

<Text style={styles.h2}>6. Data retention</Text>
<Text style={styles.p}>Fyre will retain your personal information for no longer than is necessary to fulfil the purposes described in this Privacy Notice. We may also retain certain elements of your personal information for a period after you delete or deactivate your account for our legitimate operations such as record keeping and to comply with our legal obligations. Whenever we retain your information we will do so in compliance with applicable laws.
</Text>

<Text style={styles.h2}>7. Your data protection rights</Text>
<Text style={styles.p}>You can access your account at any time to review and update your personal information. You can also contact us to ask us to delete your personal information, restrict its processing or request that it be ported to a third party. You also have the right to unsubscribe from the marketing communications we send you by using the unsubscribe functionality in any marketing communication you receive from us or by amending your profile accordingly.
</Text>

<Text style={styles.h2}>8. Links to third-party sites</Text>
<Text style={styles.p}>Our websites may, from time to time, contain links to and from third-party websites (for example, those of our partner networks, advertisers and affiliates). If you follow these links, please be aware that Fyre is not responsible or liable for these third-party websites which will have their own privacy policies.
</Text>

<Text style={styles.h2}>9. Updates to this Privacy Notice</Text>
<Text style={styles.p}>We may update this Privacy Notice from time to time in response to changing legal, technical or business developments. We encourage you to periodically review this page for the latest information on our privacy practices.
</Text>

<Text style={styles.h2}>10. How to contact us</Text>
<Text style={styles.p}>If you have any questions or concerns about this Privacy Notice and Fyre's privacy practices, please contact our Data Protection Officer.
</Text>

<Text style={styles.h1}>Updates to this Privacy Notice</Text>
<Text style={styles.p}>We may update this Privacy Notice from time to time in response to changing legal, technical or business developments. When we update our Privacy Notice, we will take appropriate measures to inform you, consistent with the significance of the changes we make. We will obtain your consent to any material Privacy Notice changes if and where this is required by applicable data protection laws.</Text>
<Text style={styles.p}>We encourage you to periodically review this page for the latest information on our privacy practices.</Text>
<Text style={styles.h1}>10. How to contact us</Text>
<Text style={styles.p}>If you have any questions or concerns about this Privacy Notice and Fyre's privacy practices, please contact us:</Text>
<Text style={styles.p}>By email at: hello@fyre.uk</Text>
<Text style={styles.p}>By writing to us at:</Text>
<Text style={styles.p}>Fyre Data Protection Officer</Text>
<Text style={styles.p}>9 Market Place, Barnard Castle, Co. Durham, DL12 8NF</Text>

<Text style={styles.p}>Alternatively, you can contact our Data Protection Officer directly by email at hello@fyre.uk.</Text>
<View style={styles.page}></View>
</View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: colors.white,
    fontFamily: fonts.regular,
  },
  container: {
    alignSelf: 'center',
    width: "100%",
    maxWidth: 1280,
  },
  header: {
    height: 90,
    paddingTop: 20,
    paddingHorizontal: 30,
    backgroundColor: colors.primary,
  },
  page: {
    height: 100,
  },
  h1: {
    fontSize: 22,
    fontFamily: fonts.bold,
    marginTop: 30,
    marginBottom: 20,
    color: colors.text
  },
  h2: {
    fontSize: 16,
    fontFamily: fonts.bold,
    color: colors.text,
    marginTop: 20,
    marginBottom: 20,
  },
  p: {
    color: colors.text,
    marginBottom: 10,
    fontFamily: fonts.regular,
  }
})
