import React from "react";
import {
    View,
    StyleSheet,
    Image
} from "react-native";


const HeaderWeb = (props) => {
    return (
      <View style={{ flexDirection: "row"}}>
        <Image
          resizeMode="contain"
          style={{
                width: 280,
                height: 45,
              }}
          source={require('../assets/family-logo-main.png')}
        />
      </View>
  )
}

export default HeaderWeb

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
});
