import React from 'react';

import { StyleSheet, Text, View, ActivityIndicator, Button, TouchableOpacity } from 'react-native';
import * as firebase from 'firebase';

import { useNavigation } from '@react-navigation/native'

import { connect, useDispatch } from 'react-redux';

import { colors } from './../constants/colors.js';
import { fonts } from './../constants/fonts.js';

import { setUsersData, watchUsersData } from './../api/usersData';

import { resetCart } from './../api/orders'


function ProfileLinks({ signOutAuth, selectLink}) {

  const navigation = useNavigation()
  const dispatch = useDispatch()

  const resetUser = () => {
    return {
      type: "RESET_USER_DATA"
    }
  }

  const resetOrders = () => {
    return {
      type: "RESET_ORDERS_DATA"
    }
  }

  const signOut = () => {
    return (
      dispatch(resetOrders()),
      dispatch(resetUser()),
      dispatch(resetCart()),
      signOutAuth()
    )
  }

  let link = global.windowWidth > 768 ? styles.linkWeb : styles.link
  let linkContainer = global.windowWidth > 768 ? styles.linkContainerWeb : styles.linkContainer

  return (
    <View style={global.windowWidth > 768 ? styles.linksWeb : styles.links}>
      <View style={linkContainer}>
        <TouchableOpacity
          onPress={() => navigation.navigate('Profile')}
          style={link}
        >
          <Text style={styles.linkHeader}>Profile</Text>
        </TouchableOpacity>
      </View>

      <View style={linkContainer}>
        <TouchableOpacity
          onPress={() => navigation.navigate('Addresses')}
          style={link}
        >
          <Text style={styles.linkHeader}>Delivery Address</Text>
        </TouchableOpacity>
      </View>

      <View style={styles.break}></View>

      <View style={linkContainer}>
        <TouchableOpacity
          onPress={() => navigation.navigate('Terms')}
          style={link}
        >
          <Text style={styles.linkHeader}>Terms and Conditions</Text>
        </TouchableOpacity>
      </View>

      <View style={linkContainer}>
        <TouchableOpacity
          onPress={() => navigation.navigate('Privacy')}
          style={link}
        >
          <Text style={styles.linkHeader}>Privacy Policy</Text>
        </TouchableOpacity>
      </View>

      <View style={styles.break}></View>

      <View style={linkContainer}>
        <TouchableOpacity
          onPress={signOut}
          style={link}
        >
          <Text style={styles.linkHeader}>Sign Out</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const mapStateToProps = (state) => {
  return {
    usersData: state.usersData,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    watchUsersData: () => dispatch(watchUsersData())
  };
}

class AccountScreen extends React.Component {

  constructor(props) {
    super(props);

    this.props.watchUsersData()

    this.state = {
      selectedLink: ""
    }
  }

  selectLink = (link) => {
    this.setState({selectedLink: link})
  }

  render() {
    return (
      <View style={styles.main}>
        <View style={styles.header}>
          <View style={[styles.container, {paddingHorizontal: global.windowWidth > 768 ? 30 : 0}]}>
          <Text style={{color: colors.secondary, fontFamily: fonts.bold, fontSize: 24}}>Your Account</Text>
          </View>
        </View>

        {this.props.usersData.isLoading ?
            <View style={{ flex: 1, justifyContent: "center", alignItems: "center"}}>
              <ActivityIndicator size="small" color={colors.primary} />
            </View>
        :<View style={[styles.container, {paddingHorizontal: global.windowWidth > 768 ? 30 : 0}]}>
          <ProfileLinks
            selectLink={this.selectLink}
            signOutAuth={() => firebase.auth().signOut()}
          />
        </View>}
      </View>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountScreen);

const styles = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: colors.white
  },
  container: {
    flex: 1,
    alignSelf: 'center',
    width: "100%",
    maxWidth: 1280,
  },
  text: {
    color: colors.text,
    padding: 20,
    fontFamily: fonts.regular
  },
  header: {
    height: 90,
    paddingTop: 20,
    paddingHorizontal: 30,
    backgroundColor: colors.primary,
  },
  break: {
    height: 30,
    borderBottomWidth: 1,
    borderColor: colors.border
  },
  linksWeb: {
    marginTop: 30,
    flexDirection: "row",
    flexWrap: 'wrap',
  },
  linkContainer: {

  },
  linkContainerWeb: {
    paddingBottom: 20,
    paddingRight: 20,
    width: "50%",
  },
  link: {
    paddingVertical: 25,
    paddingHorizontal: 30,
    borderBottomWidth: 1,
    borderColor: colors.border,
    backgroundColor: colors.white,
  },
  linkHeader: {
    fontSize: 15,
    fontFamily: fonts.regular
  },
  linkWeb: {
    paddingVertical: 25,
    paddingHorizontal: 30,
    borderWidth: 1,
    borderColor: colors.border,
    backgroundColor: colors.white,
  },
});
