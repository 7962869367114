const initialState = {
  usersData: {},
  isLoading: true,
}

const usersData = (state = initialState, action) => {
  switch(action.type) {
    case "SET_USERS_DATA":
      return { ...state, usersData: action.value, isLoading: false, };
      case 'RESET_USER_DATA':
          return initialState;
    default:
      return state;
  }
}

export default usersData;
