import React, { useState } from "react"
import {
    View,
    Text,
    StyleSheet,
    Platform,
    I18nManager,
    Animated,
    TouchableOpacity,
    ActivityIndicator,
    Alert,
    Button,
    ScrollView,
    TextInput,
    TouchableHighlight,
    Dimensions,
    FlatList
} from "react-native"

import Icon from 'react-native-vector-icons/Feather'
import { colors } from '../constants/colors'
import { fonts } from '../constants/fonts'

import Modal from 'modal-enhanced-react-native-web';

import { useDispatch, useSelector} from 'react-redux'
import {  useNavigation } from '@react-navigation/native'

import SwitchSelector from "react-native-switch-selector"

import moment from "moment";

import axios from 'axios'
import * as WebBrowser from 'expo-web-browser'
import { CommonActions } from '@react-navigation/native'
import URLs from '../constants/URLs'

import TimeModal from './TimeModal'
import NotesModal from './NotesModal'
import DiscountModal from './DiscountModal'
import PayCardModal from './PayCardModal'
import ClosedModal from './ClosedModal'

import { createOrder, resetCart } from './../api/orders'

import StripeRequestButton from './StripeRequestButton'


function BranchHours(timeSlot, hours) {
  let open = false
  let preorder = false

  // If no timeSlot it just creates a temp one
  if (timeSlot == "") {
    timeSlot = new Date()
  }

  // Finds out if branch is open to continue with the order.

  // Odd Little Opening Hours Finder.
  var status = 0

  var currentDate = new Date();
  var currentDay = currentDate.getDay()
  var currentHour = currentDate.getHours()
  var currentMinute = currentDate.getMinutes()

  var currentTime = [currentHour,currentMinute].join('')

  const now = hours.filter(hours => hours.day == currentDay && hours.opening <= currentTime && hours.closing >= currentTime)
  const today = hours.filter(hours => hours.day == currentDay)

  if (now.length > 0) { open = true }

  // Need to work out if it's a preorder.
  let timeSlotDate = new Date(timeSlot)
  let timeSlotDay = timeSlotDate.getDay()
  let timeSlotHour = timeSlotDate.getHours()
  let timeSlotMinute = timeSlotDate.getMinutes()

  let timeSlotTime = [timeSlotHour, timeSlotMinute].join('')

  // Works out if order is a preorder  - Preorder for another day
  if(timeSlotDay != currentDay) {
    preorder = true
    // Pre order for today
  } else if (today.length > 0 && timeSlotDay == currentDay && currentTime < today[0].opening) {
    preorder = true
  }

  return {
    open: open,
    preorder: preorder
  }
}

function PlaceOrderButton({cartData, userData, orderTotal, branchData, subTotal, toggleCheckoutModal, toggleCartModal, toggleOrderReceivedModal, toggleClosedModal, selectedPaymentType, formLoading, toggleFormLoading, togglePayCardModal }) {

  const dispatch = useDispatch()

  const cart = useSelector(state => state.cartData);
  var branch = useSelector(state => state.branchesData.selectedBranch);
  var user = useSelector(state => state.usersData.usersData);
  var hours = useSelector(state => state.branchesData.hoursData)

  hours = [...hours].filter(hours => hours.branch === global.branchId)

  user = user[0]

  const onPress = async () => {

    const branchHours = BranchHours(cart.time, hours)

    let open = branchHours.open
    let preorder = branchHours.preorder
    let busy = false
    // If brsnch is busy

    if (branch.isBusy == true) {
      busy = true
    }

    // If the order is all good to go, call actions.
    if (open && !busy || preorder) {
      toggleFormLoading(true)
      const order = await createOrder(cart, subTotal, user, null, null, 0, branch)
      dispatch(resetCart())
      toggleCheckoutModal()
      toggleCartModal()
      toggleOrderReceivedModal(order)
      toggleFormLoading(false)

    } else {
      toggleClosedModal()
    }


  }

  const onPressCard = () => {

    const branchHours = BranchHours(cart.time, hours)

    let open = branchHours.open
    let preorder = branchHours.preorder
    let busy = false
    // If brsnch is busy
    if (branch.isBusy == true) {
      busy = true
    }

    // If the order is all good to go, call actions.
    if (open & !busy || preorder) {
      togglePayCardModal()
    } else {
      toggleClosedModal()
    }
  }

  const onPressDisabled = () => {
    return false
  }

  return (
    <TouchableOpacity
      onPress={selectedPaymentType == 1 ? onPress : selectedPaymentType == 2 ? onPressCard : onPressDisabled}
      disabled={selectedPaymentType == null ? true : false}
      style={styles.orderButton}
    >
    {formLoading ?
    <ActivityIndicator size="small" color={colors.text} />
    : <Text style={styles.buttonText} adjustsFontSizeToFit allowFontScaling>Pay {selectedPaymentType == 1 ? "with Cash" : selectedPaymentType == 2 ? "with Credit or Debit Card" : null}</Text>}
    </TouchableOpacity>
  )
}

function CartItem({item, index, onPress}) {

  return (
    <TouchableOpacity
      style={{ paddingVertical: 20, flexDirection: "row"}}>
      <Text style={{ flex: 2, fontSize: 16, fontFamily: fonts.bold }} adjustsFontSizeToFit allowFontScaling>{item.quantity} {item.name}</Text>
      <Text style={{ flex: 1, textAlign: "right", fontFamily: fonts.regular}} adjustsFontSizeToFit allowFontScaling>£{(+item.price).toFixed(2)}</Text>
    </TouchableOpacity>
  )
}

function PaymentTypeSwitch({ paymentTypes , toggleFormLoading }) {
  const dispatch = useDispatch()

  const changePaymentType = (type) => {
    return {
      type: "UPDATE_PAYMENT_TYPE",
      payload: type,
    }
  }

  const onPress = (value) => {
    toggleFormLoading(false)
    return dispatch(changePaymentType(value))
  }

  return (
    <SwitchSelector
      initial={0}
      options={paymentTypes}
      style={{paddingVertical: 10}}
      animationDuracton={50}
      hasPadding={true}
      buttonColor={colors.primary}
      textColor={colors.text}
      textStyle={{fontSize: 13, fontFamily: fonts.regular }}
      selectedTextStyle={{ color: colors.secondary, fontSize: 13, fontFamily: fonts.regular }}
      onPress={value => onPress(value)}
    />
  )
}

class CheckoutModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isTimeModalVisible: false,
      isNotesModalVisible: false,
      isPayCardModalVisible: false,
      isClosedModalVisible: false,
      selectedPaymentType: null,
      paymentType: [],
      formLoading: false,
    }
  }

  componentDidMount() {

    let paymentTypes = []
    // Finds whether branch does collection and delivery and assigns into array for SwitchSeelector
    if (this.props.branchData.isOnlineCard == true) {
      paymentTypes.push({ label: "Card", value: 2 })
    }
    if (this.props.branchData.isOnlineCash == true) {
      paymentTypes.push({ label: "Cash", value: 1 })
    }

    //Pushes Card Paymwnt for testing
    //paymentTypes.push({ label: "Card", value: 2 })

    this.setState(state => ({
      paymentTypes: paymentTypes,
    }))

    this.props.setPaymentType(paymentTypes[0].value)
  }

  renderSeparatorView = () => {
    return (
      <View style={{
          height: 1,
          width: "100%",
          backgroundColor: "#DEDEDE",
        }}
      />
    );
  };

  renderCategoriesList = ( { item }) => {
    return (
      <CategoryItem category={item} />
    )
  }

  renderItemsList = ( { item, index }) => {
    return (
      <CartItem item={item} index={index} />
    )
  }

  selectPaymentType = (selected) => {
    this.setState(state => ({
      selectedPaymentType: selected
    }))
  }

  toggleFormLoading = (selected) => {
    this.setState(state => ({
      formLoading: selected
    }))
  }

  toggleNotesModal = () => {
    this.setState(state => ({
      isNotesModalVisible: !state.isNotesModalVisible
    }))
  }

  toggleTimeModal = () => {
    this.setState(state => ({
      isTimeModalVisible: !state.isTimeModalVisible
    }))
  }

  toggleDiscountModal = () => {
    this.setState(state => ({
      isDiscountModalVisible: !state.isDiscountModalVisible
    }))
  }

  togglePayCardModal = () => {
    this.setState(state => ({
      isPayCardModalVisible: !state.isPayCardModalVisible
    }))
  }

  toggleClosedModal = () => {
    this.setState(state => ({
      isClosedModalVisible: !state.isClosedModalVisible
    }))
  }

  renderCheckoutModal = (isVisible, toggleCheckoutModal, toggleCartModal, cartData, userData, props) => {

    let subTotal = this.props.subTotal
    let orderTotal = subTotal
    var timeSlot = 0
    var discount = 0
    var estTime = this.props.branchData.prepareTime

    if (cartData.time != null) {
      var today = moment(new Date()).format("d")
      var tomorrow = moment(new Date()).add(1, 'days').format("d")
      var cartTime = moment(cartData.time).format("d")


      if (cartTime === today) {
        var timeSlot = "Today" + moment(cartData.time).format(" - HH:mm")
      } else if (cartTime === tomorrow) {
        var timeSlot = "Tomorrow" + moment(cartData.time).format(" - HH:mm")
      } else {
        var timeSlot = moment(cartData.time).format("ddd Do MMM - HH:mm")
      }
    }

    if (cartData.discount.code != null) {
      if (cartData.discount.type == "percentage") {
        discount = (subTotal * cartData.discount.amount / 100)
        orderTotal = subTotal - discount
      } else if (cartData.discount.type == "amount") {
        discount = cartData.discount.amount
        orderTotal = subTotal - cartData.discount.amount
      }
    }

    if (cartData.customer.location != null && cartData.type == 1) {
      const deliveryCharge = cartData.customer.location[0].price
      orderTotal = orderTotal + deliveryCharge
    }

    // Works out total estimated preperating time
    if (cartData.type == 1) {
      // Finds out if it's a delivery if so add delivery time
      estTime = this.props.branchData.prepareTime + this.props.branchData.deliveryTime
    }


    // Gets open and preorder data for google/apple pay
    const branchHours = BranchHours(cartData.time, this.props.hoursData)

    return (
      <Modal
        animationIn={global.windowWidth > 768 ? "fadeIn" : "slideInUp"}
        animationOut={global.windowWidth > 768 ? "fadeOut" : "slideOutDown"}
        isVisible={this.props.isVisible}
        style={global.windowWidth > 768 ? styles.containerlWeb : styles.container}
        deviceHeight={Dimensions.get('window').height}
        deviceWidth={Dimensions.get('window').width}
      >
        <View style={[global.windowWidth > 768 ? styles.modalWeb : styles.modal]}>
          <View style={styles.header}>
            <Text style={styles.heading} adjustsFontSizeToFit allowFontScaling>Checkout</Text>
              <TouchableOpacity
                onPress={() => toggleCheckoutModal()}
                style={styles.headerClose}>
                <Icon name="x" size={25} color={colors.textSecondary}/>
            </TouchableOpacity>
          </View>

          <PaymentTypeSwitch
            paymentTypes={this.state.paymentTypes}
            isCard={this.props.branchData.isCard}
            isCash={this.props.branchData.isCash}
            toggleFormLoading={(value) => this.toggleFormLoading(value)}
          />


          <View style={styles.orderDetails}>

            <TouchableOpacity
              onPress={() => this.toggleTimeModal()}
              style={styles.orderType}>
              <Icon name="clock" style={styles.orderTypeIcon} size={24} color={colors.textSecondary}/>
              <View style={styles.orderTypeText}>
                <Text style={{ fontSize: 13, fontFamily: fonts.bold}} adjustsFontSizeToFit allowFontScaling>
                  {!cartData.time || cartData.time == "ASAP" ? "ASAP " : null}{cartData.time == "ASAP" || cartData.time == "" ? "(Est. " + (estTime-5) + "-" + estTime + " mins)" : timeSlot}
                </Text>
                <Text style={{ fontSize: 13, color: colors.textSecondary, fontFamily: fonts.regular}} adjustsFontSizeToFit allowFontScaling>Change timeslot of order</Text>
              </View>
              <Icon name="arrow-right" style={styles.orderTypeOpen} size={24} color={colors.border} light />
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => this.toggleNotesModal()}
              style={styles.orderType}>
              <Icon name="file-text" style={styles.orderTypeIcon} size={24} color={colors.textSecondary}/>
              <View style={styles.orderTypeText}>
                <Text style={{ fontSize: 13, fontFamily: fonts.bold}} adjustsFontSizeToFit allowFontScaling>Notes</Text>
                {cartData.note != "" ?
                  <Text style={{ fontSize: 13, color: colors.textSecondary, fontFamily: fonts.regular}} adjustsFontSizeToFit allowFontScaling>{cartData.note}</Text>
                  :<Text style={{ fontSize: 13, color: colors.textSecondary, fontFamily: fonts.regular}} adjustsFontSizeToFit allowFontScaling>Add note to order</Text>
                }
              </View>
              <Icon name="arrow-right" style={styles.orderTypeOpen} size={24} color={colors.border}/>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => this.toggleDiscountModal()}
              style={styles.orderType}>
              <Icon name="tag" style={styles.orderTypeIcon} size={24} color={colors.textSecondary}/>
              <View style={styles.orderTypeText}>
                <Text style={{ fontSize: 13, fontFamily: fonts.bold}} adjustsFontSizeToFit allowFontScaling>Promo Code</Text>
                <Text style={{ fontSize: 13, color: colors.textSecondary, fontFamily: fonts.regular}} adjustsFontSizeToFit allowFontScaling>Add promotional code to order</Text>
              </View>
              <Icon name="arrow-right" style={styles.orderTypeOpen} size={24} color={colors.border}/>
            </TouchableOpacity>

          </View>

          <View style={styles.receiptDetails}>

            <View style={styles.receiptValue}>
                <Text style={{ fontSize: 13, flex: 1, fontFamily: fonts.regular}} adjustsFontSizeToFit allowFontScaling>Subtotal</Text>
                <Text style={{ textAlign: "right", fontFamily: fonts.bold, fontSize: 13, flex: 1}} adjustsFontSizeToFit allowFontScaling>£{(subTotal).toFixed(2)}</Text>
            </View>


            {cartData.discount.code != "" ?

            <View style={styles.receiptValue}>
                <Text style={{ fontSize: 13, flex: 1, fontFamily: fonts.regular}} adjustsFontSizeToFit allowFontScaling>Discount</Text>
                <Text style={{ textAlign: "right", fontFamily: fonts.bold, fontSize: 13, flex: 1}} adjustsFontSizeToFit allowFontScaling>£{(discount).toFixed(2)}</Text>
            </View>

            : null }


            {cartData.customer.location != null && cartData.type == 1 ?

            <View style={styles.receiptValue}>
                <Text style={{ fontSize: 13, flex: 1, fontFamily: fonts.regular}} adjustsFontSizeToFit allowFontScaling>{cartData.customer.location[0].name} Delivery</Text>
                <Text style={{ textAlign: "right", fontFamily: fonts.bold, fontSize: 13, flex: 1}} adjustsFontSizeToFit allowFontScaling>£{(cartData.customer.location[0].price).toFixed(2)}</Text>
            </View>

            : null }

            <View style={styles.receiptValue}>
                <Text style={{ fontSize: 14, fontFamily: fonts.bold, flex: 1,}} adjustsFontSizeToFit allowFontScaling>Order Total</Text>
                <Text style={{ textAlign: "right", fontFamily: fonts.bold,fontSize: 15, flex: 1}} adjustsFontSizeToFit allowFontScaling>£{(orderTotal).toFixed(2)}</Text>
            </View>
          </View>

          { cartData.items.length > 0 && cartData.paymentType == 2 && this.props.isVisible && !this.props.branchData.isBusy && branchHours.open ||
            cartData.items.length > 0 && cartData.paymentType == 2 && this.props.isVisible && !this.props.branchData.isBusy && branchHours.preorder ?
          <StripeRequestButton
            style={{ marginBottom: 30 }}
            cartData={cartData}
            subTotal={subTotal}
            orderTotal={orderTotal}
            toggleCheckoutModal={toggleCheckoutModal}
            toggleCartModal={toggleCartModal}
            isCheckoutModalVisible={this.props.isVisible}
            togglePayCardModal={this.togglePayCardModal}
            toggleClosedModal={this.toggleClosedModal}
            toggleOrderReceivedModal={this.props.toggleOrderReceivedModal}
          />
          : null}

          {cartData.items.length > 0 ?
          <PlaceOrderButton
            cartData={cartData}
            orderTotal={orderTotal}
            subTotal={subTotal}
            userData={userData}
            toggleFormLoading={this.toggleFormLoading}
            formLoading={this.state.formLoading}
            branchData={this.props.branchData}
            selectedPaymentType={cartData.paymentType}
            toggleCheckoutModal={toggleCheckoutModal}
            toggleCartModal={toggleCartModal}
            togglePayCardModal={this.togglePayCardModal}
            toggleClosedModal={this.toggleClosedModal}
            toggleOrderReceivedModal={this.props.toggleOrderReceivedModal}
          />
          : null}
        </View>

        <TimeModal
          cartData={this.props.cartData}
          hoursData={this.props.hoursData}
          prepareTime={this.props.prepareTime}
          deliveryTime={this.props.deliveryTime}
          isPreOrder={this.props.isPreOrder}
          maxPreOrderDays={this.props.maxPreOrderDays}
          toggleTimeModal={this.toggleTimeModal}
          isVisible={this.state.isTimeModalVisible}
        />

        <NotesModal
          cartData={this.props.cartData}
          addOrderNote={this.props.addOrderNote}
          toggleNotesModal={this.toggleNotesModal}
          isVisible={this.state.isNotesModalVisible}
        />

        <DiscountModal
          cartData={this.props.cartData}
          addOrderDiscount={this.props.addOrderDiscount}
          toggleDiscountModal={this.toggleDiscountModal}
          isVisible={this.state.isDiscountModalVisible}
        />

        <ClosedModal
          toggleClosedModal={this.toggleClosedModal}
          branchData={this.props.branchData}
          isVisible={this.state.isClosedModalVisible}
        />

        <PayCardModal
          subTotal={subTotal}
          cartData={this.props.cartData}
          togglePayCardModal={this.togglePayCardModal}
          isVisible={this.state.isPayCardModalVisible}
          toggleCheckoutModal={this.props.toggleCheckoutModal}
          toggleCartModal={this.props.toggleCartModal}
          togglePayCardModal={this.togglePayCardModal}
          toggleOrderReceivedModal={this.props.toggleOrderReceivedModal}
        />

      </Modal>

    )
  }

  render() {
    return (
      <View>{this.renderCheckoutModal(this.props.isVisible, this.props.toggleCheckoutModal, this.props.toggleCartModal, this.props.cartData, this.props.userData, this.props.branchData, this.props.hoursData)}</View>
    )
  }
}

export default CheckoutModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 0,
    justifyContent: "flex-end"
  },
  containerWeb: {
    flex: 1,
    margin: 0,
    alignContent: "center",
  },
  text: {
    color: colors.text,
  },
  modal: {
    minHeight: 400,
    backgroundColor:colors.white,
    width: Dimensions.get('window').width,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    padding: 30,
  },
  modalWeb: {
    minHeight: 300,
    backgroundColor:colors.white,
    minWidth: 600,
    alignSelf: "center",
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    padding: 30,
  },
  header: {
    flexDirection: "row",
    marginBottom: 10,
  },
  headerClose: {
    flex: 0.5,
    justifyContent: "center",
    alignItems: "flex-end",
  },
  heading: {
    flex: 4,
    color: colors.text,
    fontSize: 24,
    fontFamily: fonts.bold
  },
  orderButton: {
    height: 58,
    backgroundColor: colors.primary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
  },
  buttonText: {
    fontFamily: fonts.bold,
    fontSize: 15,
    marginTop: -2,
    textAlign: "center",
    color: colors.secondary
  },
  items: {
    flex: 5,
  },
  receiptDetails: {
    paddingVertical: 20,
    marginTop: 30,
    borderTopWidth: 1,
    borderTopColor: colors.border,
  },
  receiptValue: {
    flexDirection: "row",
    paddingVertical: 5,
  },
  orderDetails: {
    flex: 2,
    borderTopWidth: 1,
    marginTop: 20,
    borderTopColor: colors.border,
    borderTopWidth: 1,
  },
  orderType: {
    flexDirection: "row",
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: colors.border,
  },
  orderTypeIcon: {
    flex: 0.5,
    marginTop: 5,
    marginRight: 10,
  },
  orderTypeOpen: {
    flex: 0.5,
    marginTop: 5,
    marginRight: 15,
    fontFamily: fonts.bold,
    alignItems: "flex-end",
    textAlign: "right"
  },
  orderTypeText: {
    flex: 5,
  },
});
