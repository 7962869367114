import React from "react";
import {
    View,
    Text,
    StyleSheet,
    Platform,
} from "react-native";

import Icon from 'react-native-vector-icons/Feather';

import { colors } from '../constants/colors';

const HamburgerIcon = (props) => (
    <View style={Platform.OS == "ios" || Platform.OS == "android" ? {paddingHorizontal: 20, marginLeft: -10, flex: 1, justifyContent: "center"} : {paddingHorizontal: 20, marginLeft: -25, flex: 1, alignItems: "right", justifyContent: "center"}}>
        <Icon name="menu" size={22} color={colors.white} />
    </View>
)

export default HamburgerIcon

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
});
