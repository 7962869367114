import React, { useState } from 'react';
import { View, StyleSheet, ActivityIndicator, Text, Image, Button, TouchableOpacity, FlatList, useWindowDimensions } from 'react-native';

import { useNavigation } from '@react-navigation/native'

import { LinearGradient } from 'expo-linear-gradient';


import * as firebase from 'firebase';
import 'firebase/firestore';

import { connect, useDispatch } from 'react-redux';

import { watchBranchesData, watchBranchesHoursData } from '../api/branchesData';
import { setItemData, watchItemData, setItemVariantData, watchItemVariantData, setDepartmentData, watchDepartmentData, setCategoryData, watchCategoryData } from './../api/itemData';
import { setUsersData, watchUsersData } from './../api/usersData'

import { selectBranch } from '../api/branches';

import { colors } from '../constants/colors';
import { fonts } from '../constants/fonts';


function BranchItem({branch, hours}) {

  const [hover, setHover] = useState(false);
  const dispatch = useDispatch()

  const onPress = () => {
    dispatch(selectBranch(branch))
  }


  // Odd Little Opening Hours Finder.
  var status = 0

  var currentDate = new Date();
  var currentDay = currentDate.getDay()
  var currentHour = currentDate.getHours()
  var currentMinute = currentDate.getMinutes()

  var currentTime = [currentHour,currentMinute].join('')

  const today = hours.filter(hours => hours.day == currentDay && hours.opening <= currentTime && hours.closing >= currentTime )

  var open = "Closed"
  if (today.length > 0) { open = "Open"}

  return (
    <TouchableOpacity
      style={[global.windowWidth > 768 ? styles.branchWeb : styles.branch, { height: branch.onlineCoverImage ? 300 : 115 }]}
      onPress={branch.isCollection || branch.isDelivery ? onPress : null}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      disabled={branch.isCollection || branch.isDelivery ? false : true}
    >
    <View style={styles.branchHeader}>
        {branch.onlineCoverImage ?
        <Image
          resizeMode="cover"
          style={styles.branchImage}
          source={{uri: branch.onlineCoverImage}}
        />
        : null}

        {branch.onlineCoverImage ?
        <LinearGradient
         colors={[
          'transparent',
           '#111',
         ]}
         locations={hover ? [0.2,1] : [0,1]}
         style={styles.linearGradient}
       />
       : null}
    </View>

    <View style={styles.branchInfo}>
      <View style={{ flex: 2}}>
        <Text style={{fontSize: 18, color: branch.onlineCoverImage ? colors.white : colors.text, fontFamily: fonts.bold}} adjustsFontSizeToFit allowFontScaling>{branch.location}</Text>
        <Text style={{ fontSize: 13, marginTop: 5, color: branch.onlineCoverImage ? colors.whiteSecondary : colors.textSecondary, fontFamily: fonts.regular}} numberOfLines={1} adjustsFontSizeToFit allowFontScaling>{branch.line1}, {branch.town}, {branch.postCode}.</Text>

        <Text style={{ fontSize: 13, marginTop: 5, color: branch.onlineCoverImage ? colors.white : colors.text, fontFamily: fonts.regular}}>
        {branch.isCollection && branch.isDelivery ?
          <Text adjustsFontSizeToFit allowFontScaling>Collection & Delivery</Text>
        : branch.isCollection ?
          <Text adjustsFontSizeToFit allowFontScaling>Collection</Text>
        : branch.isDelivery ?
          <Text adjustsFontSizeToFit allowFontScaling>Delivery</Text>
        : <Text adjustsFontSizeToFit allowFontScaling>Unavailable</Text>
        }
        </Text>
      </View>
      <View style={{ marginTop: 5, flex: 1.5, alignItems: "flex-end"}}>

        {open == "Open" ?
        <View style={{ padding: 5, backgroundColor: colors.green, width: 90,  borderRadius: 2}}>
          <Text style={{fontSize: 13, textAlign:"center", color: colors.white, fontFamily: fonts.bold,}} adjustsFontSizeToFit allowFontScaling>Open</Text>
        </View>
        : open == "Closed" && branch.isPreOrder == true ?
        <View style={{ padding: 5, backgroundColor: colors.yellow, width: 90,  borderRadius: 2}}>
          <Text style={{fontSize: 13, textAlign:"center", color: colors.white, fontFamily: fonts.bold}} adjustsFontSizeToFit allowFontScaling>Pre Order</Text>
        </View>
        :         <View style={{ padding: 5, backgroundColor: colors.red, width: 90,  borderRadius: 2}}>
                  <Text style={{fontSize: 13, textAlign:"center", color: colors.white, fontFamily: fonts.bold}} adjustsFontSizeToFit allowFontScaling>Closed</Text>
                </View>
              }

        {today.length > 0 ?
          <Text style={{ marginTop: 6, fontSize: 12, color: branch.onlineCoverImage ? colors.whiteSecondary : colors.textSecondary, fontFamily: fonts.regular }}>{today[0].opening.replace(/(..)/g, '$1:').slice(0,-1)}-{today[0].closing.replace(/(..)/g, '$1:').slice(0,-1)}</Text>
        : null}
      </View>
    </View>

    </TouchableOpacity>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    watchUsersData: () => dispatch(watchUsersData()),
    watchBranchesData: () => dispatch(watchBranchesData()),
    watchBranchesHoursData: () => dispatch(watchBranchesHoursData()),
    assignBranch: (branch) => dispatch(selectBranch(branch)),
  };
}

class BranchesScreen extends React.Component {

  constructor(props) {
      super(props);

      this.props.watchUsersData(),
      this.props.watchBranchesData(),
      this.props.watchBranchesHoursData()

      this.state = { data: null }
  }

  componentDidMount() {

    // If there is only one brasnch available then assign and then go onto next page.
    if (this.props.branches.length == 1 ) {
      this.props.assignBranch(this.props.branches[0])
    }
  }

  renderSeparatorView = () => {
    return (
      <View style={{
          height: 1,
          width: "100%",
          backgroundColor: colors.border,
        }}
      />
    );
  };

  renderBranchesList = ( { item }) => {
    const hours = this.props.hours.filter(hours => hours.branch === item.id)
    return (
      <BranchItem branch={item} hours={hours} />
    )
  }

  render() {
    return (
      <View style={styles.main}>
        <View style={styles.header}>
          <View style={[styles.container, {paddingHorizontal: global.windowWidth > 768 ? 30 : 30} ]}>
          <Text style={{ color: colors.white, fontSize: 24, fontFamily: fonts.bold }} adjustsFontSizeToFit allowFontScaling>Choose Location</Text>
          </View>
        </View>

        <View style={[styles.container, { paddingHorizontal: global.windowWidth > 768 ? 30 : 0, paddingTop: global.windowWidth > 768 ? 0 : 0}]}>
          <FlatList
            data={this.props.branches}
            keyExtractor={(branch, index) => branch.id.toString()}
            renderItem={(item) => this.renderBranchesList(item)}
            numColumns={global.windowWidth > 768 ? 2 : 1}
          />
        </View>
      </View>
    )
  }
}

export default connect(null, mapDispatchToProps)(BranchesScreen);

const styles = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: colors.white
  },
  container: {
    flex: 1,
    alignSelf: 'center',
    width: "100%",
    maxWidth: 1280,
  },
  text: {
    color: colors.text,
    padding: 20,
    fontFamily: fonts.regular,
  },
  header: {
    height: 90,
    paddingTop: 20,
    backgroundColor: colors.primary,
  },
  branch: {
    borderBottomWidth: 1,
    borderBottomColor: colors.border
  },
  branchWeb: {
    flex: 1,
    borderWidth: 1,
    marginRight: 20,
    marginTop: 20,
    borderColor: colors.border
  },
  branchInfo: {
    marginTop:-95,
    flexDirection: "row",
    paddingHorizontal: 30,
    paddingBottom: 26,
  },
  branchHeader: {
    flex: 1,
    backgroundColor: colors.grey
  },
  branchImage: {
    flex: 1,
    width: '100%',
    height: 300,
    backgroundColor: colors.grey
  },
  linearGradient: {
    position:'absolute',
    width:'100%',
    height:'100%'
  }
});
