const initialState = {
  branchesData: [],
  hoursData: [],
  selectedBranch: null,
  isLoading: true,
}

const branchesData = (state = initialState, action) => {
  switch(action.type) {
    case "SET_BRANCHES_DATA":
      return { ...state, branchesData: action.value, isLoading: false, };
      case "SET_BRANCHES_HOURS_DATA":
        return { ...state, hoursData: action.value };
      case "SELECT_BRANCH":
        return { ...state, selectedBranch: action.value };
    case "CHANGE_BRANCH":
      return { ...state, selectedBranch : null }
    default:
      return state;
  }
}

export default branchesData;
