import React from "react";
import {
    View,
    Text,
    StyleSheet,
    Platform,
    I18nManager,
    Animated,
    TouchableOpacity,
    Alert,
    Button,
    ScrollView,
    TextInput,
    TouchableHighlight,
    Dimensions,
    FlatList,
    KeyboardAvoidingView
} from "react-native";

import Icon from 'react-native-vector-icons/Feather';
import { colors } from '../constants/colors';

import Modal from 'modal-enhanced-react-native-web';

import { useDispatch, useSelector} from 'react-redux'
import {  useNavigation } from '@react-navigation/native'

import StripeCardForm from './StripeCardForm';


class PayCardModal extends React.Component {

  constructor(props) {
    super(props);

  }

  renderPayCardModal = (isVisible, togglePayCardModal, cartData) => {
    return (
      <Modal
        animationIn={global.windowWidth > 768 ? "fadeIn" : "slideInUp"}
        animationOut={global.windowWidth > 768 ? "fadeOut" : "slideOutDown"}
        isVisible={this.props.isVisible}
        style={global.windowWidth > 768 ? styles.containerlWeb : styles.container}
        deviceHeight={Dimensions.get('window').height}
        deviceWidth={Dimensions.get('window').width}
      >
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
        <View style={[global.windowWidth > 768 ? styles.modalWeb : styles.modal]}>

              <View style={[styles.header, { paddingHorizontal: 30, paddingTop: 30,}]}>
                <Text style={styles.heading} adjustsFontSizeToFit allowFontScaling>Pay Card</Text>
                  <TouchableOpacity
                    onPress={() => togglePayCardModal()}
                    style={styles.headerClose}>
                    <Icon name="x" size={25} color={colors.textSecondary}/>
                </TouchableOpacity>
              </View>

              <View style={{ paddingTop: 10, paddingHorizontal: 30}}>
                <StripeCardForm
                  submitForm={this.submitForm}
                  cartData={cartData}
                  subTotal={this.props.subTotal}
                  toggleCheckoutModal={this.props.toggleCheckoutModal}
                  toggleCartModal={this.props.toggleCartModal}
                  togglePayCardModal={this.props.togglePayCardModal}
                  isPayCardModalVisible={this.props.isVisible}
                  toggleOrderReceivedModal={this.props.toggleOrderReceivedModal}
                />

                <View style={{ justifyContent: 'center', marginTop: 10, flex: 1, flexDirection: "row"}}>
                  <Icon name="lock" size={11} color={colors.text} style={{ paddingTop: 1, }}/>
                  <Text style={{ color: colors.textSecondary, fontSize: 11, paddingLeft: 5 }}>Your card information is encrypted.</Text>
                </View>
              </View>
          </View>
          </KeyboardAvoidingView>
        </Modal>
    )
  }

  render() {
    return (
      <View>{this.renderPayCardModal(this.props.isVisible, this.props.togglePayCardModal, this.props.cartData)}</View>
    )
  }
}

export default PayCardModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 0,
    justifyContent: "flex-end"
  },
  containerWeb: {
    flex: 1,
    margin: 0,
    alignContent: "center",
  },
  text: {
    color: colors.text,
  },
  modal: {
    minHeight: 240,
    backgroundColor:colors.white,
    width: Dimensions.get('window').width,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
  },
  modalWeb: {
    minHeight: 240,
    backgroundColor:colors.white,
    minWidth: 600,
    maxWidth: 600,
    alignSelf: "center",
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
  },
  header: {
    flexDirection: "row",
    marginBottom: 10,
  },
  headerClose: {
    flex: 0.5,
    alignItems: "flex-end",
  },
  heading: {
    flex: 4,
    color: colors.text,
    fontSize: 24,
    fontWeight: "700",
  },
  orderButton: {
    backgroundColor: colors.secondary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  orderButtonDisabled: {
    backgroundColor: colors.secondary,
    opacity: 0.8,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  buttonText: {
    fontWeight: "700",
    fontSize: 16,
    textAlign: "center",
    color: colors.primary
  },
  items: {
    flex: 5,
    paddingVertical: 20,
  },
  receiptDetails: {
    flex: 0.5,
    borderTopWidth: 1,
    borderTopColor: colors.border
  },
  orderDetails: {
    flex: 2,
    borderTopWidth: 1,
    borderTopColor: colors.border
  },
  orderType: {
    flexDirection: "row",
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: colors.border
  },
  orderTypeIcon: {
    marginTop: 5,
    marginRight: 15,
  },
  orderTypeOpen: {
    marginTop: 5,
    marginRight: 15,
    alignContent: "flex-end"
  },
  orderTypeText: {
  },
  receiptDetails: {
    flex: 1.5,
  },
  subHeading: {
    fontSize: 12,
    letterSpacing: 2,
    marginVertical: 10,
    textTransform: "uppercase",
    fontWeight: "700"
  },
  findPostcodeInput: {
    flex: 4,
    paddingVertical: 20,
    paddingHorizontal: 20,
    borderWidth: 1,
    borderColor: colors.border
  },
  findPostcodeButton: {
    flex: 1,
    paddingVertical: 20,
    paddingHorizontal: 20,
    backgroundColor: colors.primary,
  }
});
