import React from 'react';

import { StyleSheet, Text, View, ActivityIndicator } from 'react-native';
import { colors } from './../constants/colors.js';

export default class LoadingScreen extends React.Component {

  render() {
    return (
      <View style={styles.container}>
        <ActivityIndicator color={colors.secondary} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
