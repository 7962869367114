import React from "react";
import {
    View,
    Text,
    StyleSheet,
    Platform,
    I18nManager,
    Animated,
    TouchableOpacity,
    Alert,
    Button,
    ScrollView,
    TextInput,
    TouchableHighlight,
    Dimensions,
    FlatList,
} from "react-native";

import Icon from 'react-native-vector-icons/Ionicons';

import { colors } from '../constants/colors';

import RNPickerSelect, { defaultStyles } from 'react-native-picker-select';

import { fonts } from '../constants/fonts'


class CustomerSelectAddress extends React.Component {

  constructor(props) {
    super(props);

  }

  items = () => {
    const itemss = []

    // Finds Addresses and then formats then for Picker

    if (typeof this.props.addresses != "undefined") {
      this.props.addresses.map((address, index) => {
        let formattedAddress = ""

        if (address.line_2 == "") {
          formattedAddress = address.line_1 + ", " + address.town_or_city
        } else {
          formattedAddress = address.line_1 + ", " + address.line_2 + ", " + address.town_or_city
        }

        itemss.push({
          label: formattedAddress.toString(),
          value: address
        })
      })
    }


    // Add address manually

    itemss.push({
      label: "Add address manually",
      value: "manual"
    })

    return itemss
  }

  renderCustomerFindAddress = (postcode, toggle, isVisible, addresses, selectAddress, selectedAddress) => {
    return (
      <View>
      {isVisible ?
      <View style={styles.container}>
      <Text style={styles.inputTitle} adjustsFontSizeToFit allowFontScaling>Select Address</Text>
          <RNPickerSelect
              placeholder={ { label: "Select your address...", value: 0}}
              style={{...pickerSelectStyles}}
              onValueChange={(value, index) => selectAddress(addresses[index - 1])}
              items={this.items()}
              textInputProps={{color: colors.textSecondary, fontFamily: fonts.regular}}
              Icon={() => {
                return <Icon name="ios-arrow-down" size={15} color="gray" />;
              }}
          />
      </View>
      : null }
    </View>
    )
  }

  render() {

    return (
      <View>{this.renderCustomerFindAddress(this.props.postcode, this.props.toggleFindAddress, this.props.isVisible, this.props.addresses, this.props.selectAddress, this.props.selectedAddress)}</View>
    )
  }
}

export default CustomerSelectAddress;

const styles = StyleSheet.create({
  container: {
    paddingBottom: 20,
  },
  text: {
    color: colors.text,
  },
  modalRow: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  inputTitle: {
    color: colors.text,
    fontWeight: "600",
    fontSize: 14,
  },
  inputBorder: {
    padding: 10,
    borderColor: colors.border,
    borderWidth: StyleSheet.hairlineWidth,
    marginTop: 10,
  },
  input: {
    width: 530,
    fontSize: 15,
    paddingVertical: 20,
    paddingHorizontal: 30,
    color: colors.textSecondary,
  },
  field: {
    paddingRight: 30,
    paddingBottom: 10,
  },

});

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    marginTop: 10,
    borderWidth: 1,
    borderColor: colors.border,
    color: colors.text,
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  inputWeb: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    marginTop: 10,
    borderWidth: 1,
    borderColor: colors.border,
    color: colors.text,
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  inputAndroid: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    marginTop: 10,
    borderWidth: 1,
    borderColor: colors.border,
    color: colors.text,
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  iconContainer: {
    top: 30,
    right: 20,
  },
});
