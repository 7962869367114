import React from 'react';
import { WebView } from 'react-native-web-webview';
import { ToastAndroid } from 'react-native';
import { connect } from 'react-redux';
import { CommonActions, StackActions } from '@react-navigation/native';

import { useDispatch, useSelector } from 'react-redux'
import { createOrder, resetCart } from './../api/orders'

import URLs from '../constants/URLs';

const mapStateToProps = (state) => {
  return {
    usersData: state.usersData.usersData,
    cartData: state.cartData,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetCart: (type) => dispatch({type: 'RESET_CART'})
  };
}

class CheckoutWebViewScreen extends React.Component {

  constructor(props) {
    super(props);
  }


  handleChange = (e) => {
    console.log(e.url);
    if(!e.loading && e.url===`${URLs.BASE_API}/payment/success`) {
      this.props.resetCart,
      this.props.navigation.dispatch(StackActions.popToTop());
      this.props.navigation.dispatch(CommonActions.navigate('Orders', {orderId: this.props.route.params.orderId}));
    }
    else if(!e.loading && e.url===`${URLs.BASE_API}/payment/cancel`) {
      this.props.navigation.goBack();
      ToastAndroid.show('Payment Cancelled.', ToastAndroid.SHORT);
    }
  }

  render() {

    return (
      <WebView
        originWhitelist={['*']}
        source={{ uri: `${URLs.BASE_API}/checkout/redirect?sessionId=${this.props.route.params.sessionId}`}}
        onNavigationStateChange={this.handleChange}
        ref={(ref) => { webview = ref; }}
      />
    )

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutWebViewScreen);
