import React, { Component } from "react";
import {
  View,
  Text,
  StyleSheet,
  Platform,
  TouchableOpacity,
  Image,
  ActivityIndicator
} from "react-native";

import { colors } from '../constants/colors';
import { fonts } from  '../constants/fonts';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faSeedling } from '@fortawesome/free-solid-svg-icons/faSeedling'
import { faCarrot } from '@fortawesome/free-solid-svg-icons/faCarrot'
import { faWineGlass } from '@fortawesome/free-solid-svg-icons/faWineGlass'

import ItemAddModal from './ItemAddModal'


// <View style={[{ paddingVertical: 30, paddingHorizontal: 30}, global.windowWidth > 768 ? { width: "42%", borderColor: colors.border, borderWidth: 1, marginRight: 20, marginBottom: 20} : { flex: 1, borderColor: colors.border, borderTopWidth: 1}]}>

class OrderItem extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {

    const lowestVariantPrice = 0
    let variants = this.props.variants

    if (variants.length > 0) {
      variants.sort(function (a, b) {
        return a.price - b.price
      })
    }


    // Sorts allergens by name
    if (this.props.item.allergens) {
      this.props.item.allergens.sort()

      // Sorts Vegan, Vegetarian and Alchol to the top of the list to display first
      this.props.item.allergens.filter(item => item !== "vegan");

      this.props.item.allergens.includes('vegan') ? this.props.item.allergens = ['vegan', ...this.props.item.allergens.filter(item => item !== 'vegan')] : null
      this.props.item.allergens.includes('vegetarian') ? this.props.item.allergens = ['vegetarian', ...this.props.item.allergens.filter(item => item !== 'vegetarian')] : null
      this.props.item.allergens.includes('alcohol') ? this.props.item.allergens = ['alcohol', ...this.props.item.allergens.filter(item => item !== 'alcohol')] : null

    }

    return (
      <TouchableOpacity
        style={[styles.container, global.windowWidth > 768 ? { borderColor: colors.border, borderWidth: 1} : {  borderColor: colors.border, borderTopWidth: 1}]}
        onPress={() => this.props.openItemModal(this.props.item)}
        disabled={this.props.item.isAvailable != undefined && this.props.item.isAvailable == false ? true : false}
      >

        <View style={styles.main}>
        {this.props.item.isAvailable != undefined && this.props.item.isAvailable == false ?
          <Text style={styles.unavailableLabel} adjustsFontSizeToFit allowFontScaling numOfLines={1}>Unavailable</Text>
        : null }

        <Text style={[styles.name, { fontFamily: fonts.bold }]} allowFontScaling numOfLines={1}>
          {this.props.item.name}
          {this.props.item.allergens ? this.props.item.allergens.includes('vegan') ? <FontAwesomeIcon style={styles.allergen} size={15} icon={ faSeedling } /> : null : null}
          {this.props.item.allergens ? this.props.item.allergens.includes('vegetarian') ? <FontAwesomeIcon style={styles.allergen} size={15} icon={ faCarrot } /> : null : null}
          {this.props.item.allergens ? this.props.item.allergens.includes('alcohol') ? <FontAwesomeIcon style={styles.allergen} size={15} icon={ faWineGlass } /> : null : null}
        </Text>

        {this.props.item.description ?
        <Text style={[styles.description, { fontFamily: fonts.regular }]} numberOfLines={1} allowFontScaling>{this.props.item.description}</Text>
        : null}

        {this.props.variants.length > 0 ?
        <Text style={{ fontSize: 14, fontFamily: fonts.regular, color: colors.textSecondary, justifyContent: "flex-end"}} allowFontScaling>From £{(+variants[0].price).toFixed(2)} {this.props.item.calories ? <Text style={styles.calories}>{'\u2022'} {this.props.item.calories} kcal</Text> : null}</Text>
        : <Text style={{ fontSize: 14, fontFamily: fonts.regular , color: colors.textSecondary, justifyContent: "flex-end"}} allowFontScaling>£{(+this.props.item.price).toFixed(2)} {this.props.item.calories ? <Text style={styles.calories}>{'\u2022'} {this.props.item.calories} kcal</Text> : null}</Text>}
        </View>

        {this.props.item.image ?
        <Image
          resizeMode="cover"
          accessibilityLabel={"Image of " + this.props.item.name}
          style={styles.image}
          loadingIndicatorSource={<ActivityIndicator style={styles.imageLoading} color={colors.primary} />}
          source={{uri: this.props.item.image}}
        />
        : null}
      </TouchableOpacity>
    )
  }
}

export default OrderItem;



const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: 126,
    flexDirection: "row"
  },
  main: {
    flex: 2,
    paddingVertical: 30,
    paddingHorizontal: 30,
    justifyContent: "center"
  },
  image: {
    flex: 1.5,
    backgroundColor: colors.grey
  },
  imageLoading: {
     flex: 1,
     justifyContent: "center",
     alignItems: "center",
  },
  unavailableLabel: {
    backgroundColor: colors.border,
    color: colors.textSecondary,
    fontSize: 12,
    marginRight: -0,
    paddingVertical: 7,
    paddingHorizontal: 12,
    marginTop: -102,
    position: "absolute",
    fontFamily: fonts.regular,
    right: 0,
  },
  name: { color: colors.text, fontSize: 14, marginBottom : 10, fontFamily: fonts.bold, justifyContent: "flex-start" },
  description: { fontSize: 13, color: colors.textSecondary, marginBottom : 10, fontFamily: fonts.regular, justifyContent: "flex-start"},
  calories: {
    opacity: 0.7
  },
  allergen: {
    color: colors.textSecondary,
    paddingLeft: 3,
    opacity: 0.9,
    position: "relative",
    top: 2,
  }
})
