
import { createStore, applyMiddleware, useSelector } from 'redux';
import thunkMiddleware from 'redux-thunk';

import * as firebase from 'firebase';
import 'firebase/firestore';
// Set Orders Data

const setUsersData = (usersData) => {
  return {
    type: "SET_USERS_DATA",
    value: usersData,
  }
};

const watchUsersData = (usersData, company) => {

  var user = firebase.auth().currentUser;

  if (user) {
  return function(dispatch) {
    backendFirebase.firestore()
        .collection("/customers/" + global.companyId + "/customer/")
        .where("uid", "==", user.uid)
        .limit(1)
        .onSnapshot(querySnapshot => {
          const data = querySnapshot.docs.map(doc => {
            const id = doc.id
            const data = doc.data()
            return Object.assign({id, ...data})
          });
          var actionSetUsersData = setUsersData(data);
          global.customerId = data[0].id

          dispatch(actionSetUsersData);
        });
  }} else {
    return function(dispatch) {
      var actionSetUsersData = setUsersData("");
      dispatch(actionSetUsersData)
    }
  }
};



export {setUsersData, watchUsersData};
