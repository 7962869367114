const initialState = {
  ordersData: [],
  orderDetailsData: [],
  isLoading: true,
}

const ordersData = (state = initialState, action) => {
  switch(action.type) {
    case "SET_ORDERS_DATA":
      return { ...state, ordersData: action.value, isLoading: false, };
    case "SET_ORDER_DETAILS_DATA":
      return { ...state, orderDetailsData: action.value };
      case 'RESET_ORDERS_DATA':
          return initialState;
    default:
      return state;
  }
}

export default ordersData;
