
const getAddresses = (postcode) => new Promise((resolves, rejects) => {
  const api = 'https://api.getAddress.io/find/' + postcode + '?api-key=s6tu3ecM506KblocdI1b1Q29699&format&true&sort=true&expand=true'
  const request = new XMLHttpRequest()

  request.open('GET', api)
  request.onload = () =>
    (request.status === 200 || request.status === 404) ?
      resolves(JSON.parse(request.response).addresses) :
    (request.status === 400) ?
      resolves(400) :
    rejects(Error(request.status))

  request.onerror = (err) => rejects(err)
  request.send()
})

export {getAddresses}
