
import { createStore, applyMiddleware, useSelector } from 'redux';
import thunkMiddleware from 'redux-thunk';

import * as firebase from 'firebase';
import 'firebase/firestore';

// Set Orders Data

const setDeliveriesData = (deliveriesData) => {
  return {
    type: "SET_DELIVERIES_DATA",
    value: deliveriesData,
  }
};

const watchDeliveriesData = (deliveriesData, company) => {

  return function(dispatch) {
    global.backendFirebase.firestore()
        .collection("/deliveries/" + global.companyId + "/postcodes/")
        .where("branch", "==", global.branchId)
        .onSnapshot(querySnapshot => {
          const data = querySnapshot.docs.map(doc => {
            const id = doc.id
            const data = doc.data()
            return Object.assign({id, ...data})
          });
          var actionSetDeliveriesData = setDeliveriesData(data);
          dispatch(actionSetDeliveriesData);
        });
  }
};


export { setDeliveriesData, watchDeliveriesData };
