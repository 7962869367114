import React from 'react';
import { useState, useEffect, View, Text } from 'react';

import * as firebase from 'firebase';
import 'firebase/firestore';

// Screens
import LoadingScreen from './../screens/LoadingScreen';

// Navigation
import { AuthStack } from './../navigation/AuthStack';
import { AccountStack } from './../navigation/AccountStack';

export default function App(props) {

  // Set an initializing state whilst Firebase connects
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState();


  // Handle user state changes
  function onAuthStateChanged(user) {
    setUser(user);
    if (initializing) setInitializing(false);
  }

  useEffect(() => {
    const subscriber = firebase.auth().onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
  }, []);

  if (initializing) {
    return (
      <LoadingScreen />
    )
  }

  if (!user) {
    return (
      <AuthStack {...props} />
    );
  } else {
    return (
      <AccountStack {...props} />
    );
  }
}
