import React from "react";
import {
    View,
    Text,
    StyleSheet,
    Platform,
    I18nManager,
    Animated,
    TouchableOpacity,
    Alert,
    Button,
    ScrollView,
    TextInput,
    TouchableHighlight,
    Dimensions,
    FlatList,
    KeyboardAvoidingView
} from "react-native";

import Icon from 'react-native-vector-icons/Feather';
import { colors } from '../constants/colors';
import { fonts } from '../constants/fonts';

import Modal from 'modal-enhanced-react-native-web';

import { useDispatch, useSelector} from 'react-redux'
import {  useNavigation } from '@react-navigation/native'

function FormatOrderNumber(num) {
    var r = "" + num;
    while (r.length < 3) {
        r = "0" + r;
    }
    return r;
}


function ViewOrderButton({ order, toggleModal }) {

  const navigation = useNavigation()

  const onPress = () => {
    toggleModal(),
    navigation.navigate('Orders', {
      screen: 'View',
      params: { id: order.orderId }
    })
  }

  return (
    <TouchableOpacity
      onPress={onPress}
      style={styles.orderButton}
    >
      <Text style={styles.buttonText} adjustsFontSizeToFit allowFontScaling>View Order</Text>
    </TouchableOpacity>
  )
}

class OrderReceivedModal extends React.Component {

  constructor(props) {
    super(props);
  }

  renderOrderReceivedModal = (isVisible, toggleOrderReceivedModal, order) => {

    return (
      <Modal
        animationIn={global.windowWidth > 768 ? "fadeIn" : "slideInUp"}
        animationOut={global.windowWidth > 768 ? "fadeOut" : "slideOutDown"}
        isVisible={this.props.isVisible}
        style={global.windowWidth > 768 ? styles.containerlWeb : styles.container}
        deviceHeight={Dimensions.get('window').height}
        deviceWidth={Dimensions.get('window').width}
      >
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
        <View style={[global.windowWidth > 768 ? styles.modalWeb : styles.modal]}>

              <View style={{ paddingTop: 10, paddingHorizontal: 30}}>

              <Text style={[styles.subHeading, {     marginTop: 10,
                  marginBottom: 0,color: colors.textSecondary, fontSize: 15,}]}>Order #{order != null ? FormatOrderNumber(order.orderNo) : null }</Text>

              <View style={styles.header}>
                <Text style={styles.heading}>We've received your {'\n'}order{order != null ? ", " + order.customer.firstName : null }!</Text>
              </View>

              <Text style={[styles.subHeading, { color: colors.text}]}>Check your email for your accepted timeslot.</Text>

              <Text style={[styles.text, { marginBottom: 20, color: colors.textSecondary }]}>Not received an email? Please check your spam. If you have placed a preorder, it may not be accepted until the day you requested.</Text>

              { order != null && order.customer.id != undefined ?
              <ViewOrderButton
                order={order}
                toggleModal={() => toggleOrderReceivedModal()}
              />
              : null}

              <TouchableOpacity
                style={[styles.buttonBorder, { marginBottom: 10, marginTop: 15, }]}
                onPress={() => toggleOrderReceivedModal()}
              >
                <Text style={styles.buttonText} adjustsFontSizeToFit allowFontScaling>Close</Text>
              </TouchableOpacity>
              </View>
          </View>
          </KeyboardAvoidingView>
        </Modal>
    )
  }

  render() {
    return (
      <View>{this.renderOrderReceivedModal(this.props.isVisible, this.props.toggleOrderReceivedModal, this.props.orderDetails)}</View>
    )
  }
}

export default OrderReceivedModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 0,
    justifyContent: "flex-end"
  },
  containerWeb: {
    flex: 1,
    margin: 0,
    alignContent: "center",
  },
  text: {
    color: colors.text,
    fontFamily: fonts.regular,
  },
  modal: {
    paddingVertical: 30,
    minHeight: 190,
    backgroundColor:colors.white,
    width: Dimensions.get('window').width,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
  },
  modalWeb: {
    paddingVertical: 30,
    minHeight: 190,
    backgroundColor:colors.white,
    minWidth: 500,
    maxWidth: 500,
    alignSelf: "center",
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
  },
  header: {
    flexDirection: "row",
    marginTop: 10,
    marginBottom: 15,
  },
  headerClose: {
    flex: 0.5,
    alignItems: "flex-end",
  },
  heading: {
    flex: 4,
    color: colors.text,
    fontSize: 28,
    fontFamily: fonts.bold,
  },
  orderButton: {
    backgroundColor: colors.secondary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  buttonBorder: {
    height: 58,
    borderWidth: 2,
    borderColor: colors.primary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
  },
  orderButtonDisabled: {
    backgroundColor: colors.secondary,
    opacity: 0.8,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  buttonText: {
    fontFamily: fonts.bold,
    fontSize: 15,
    textAlign: "center",
    color: colors.primary
  },
  items: {
    flex: 5,
    paddingVertical: 20,
  },
  receiptDetails: {
    flex: 0.5,
    borderTopWidth: 1,
    borderTopColor: colors.border
  },
  orderDetails: {
    flex: 2,
    borderTopWidth: 1,
    borderTopColor: colors.border
  },
  orderType: {
    flexDirection: "row",
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: colors.border
  },
  orderTypeIcon: {
    marginTop: 5,
    marginRight: 15,
  },
  orderTypeOpen: {
    marginTop: 5,
    marginRight: 15,
    alignContent: "flex-end"
  },
  orderTypeText: {
  },
  receiptDetails: {
    flex: 1.5,
  },
  subHeading: {
    fontSize: 18,
    marginTop: 0,
    marginBottom: 15,
    fontFamily: fonts.bold,
    color: colors.textSecondary,
  },
  findPostcodeInput: {
    flex: 4,
    paddingVertical: 20,
    paddingHorizontal: 20,
    borderWidth: 1,
    borderColor: colors.border
  },
  findPostcodeButton: {
    flex: 1,
    paddingVertical: 20,
    paddingHorizontal: 20,
    backgroundColor: colors.primary,
  }
});
