import * as React from 'react';
import { View, StyleSheet, ActivityIndicator, Text, Button, TouchableOpacity } from 'react-native';

import { useNavigation } from '@react-navigation/native'

import * as firebase from 'firebase';
import 'firebase/firestore';

import { connect, useDispatch } from 'react-redux';
import { watchBranchesData, watchBranchesHoursData } from '../api/branchesData';
import { selectBranch } from '../api/branches';

import BranchesScreen from './BranchesScreen'
import OrderScreen from './OrderScreen'

import { setItemData, watchItemData, setItemVariantData, watchItemVariantData, setDepartmentData, watchDepartmentData, setCategoryData, watchCategoryData } from './../api/itemData';
import { setUsersData, watchUsersData } from './../api/usersData'

import { changeBranch } from '../api/branches';

import { colors } from '../constants/colors';

const mapStateToProps = (state) => {
  return {
    usersData: state.usersData,
    branchesData: state.branchesData,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    watchUsersData: () => dispatch(watchUsersData()),
    watchBranchesData: () => dispatch(watchBranchesData()),
    watchBranchesHoursData: () => dispatch(watchBranchesHoursData()),
  };
}

function OpenDrawer() {
  const navigation = useNavigation()

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.toggleDrawer()
      }}
    >
      <Text>Open Me</Text>
    </TouchableOpacity>
  )
}

class HomeScreen extends React.Component {

  constructor(props) {
      super(props);
      this.props.watchUsersData()
      this.props.watchBranchesData(),
      this.props.watchBranchesHoursData()

      this.state = {
        isUserLoading: true
      }
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {

      // This makes everything wait till Firebase is initiated and that we have a user if there is one.
      if (user) {
        this.setState(state => ({
          isUserLoading: false
        }))
      } else {
        this.setState(state => ({
          isUserLoading: false
        }))
      }
    });
  }

  render() {

    if (this.props.branchesData.isLoading || this.state.isUserLoading) {
      return (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center"}}>
          <ActivityIndicator size="small" color={colors.primary} />
        </View>
      )
    } else if (this.props.branchesData.selectedBranch != null) {
    return (
      <OrderScreen />
    )
  } else {
    return (
      <BranchesScreen
        branches={this.props.branchesData.branchesData}
        hours={this.props.branchesData.hoursData}
      />
    )
  }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    alignItems: 'center',
    textAlign: "center",
  }
});
