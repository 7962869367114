import React from "react";
import MetaTags from 'react-meta-tags';

const MetaData = () => {

  return (
    <MetaTags>
    </MetaTags>
  );
};

export default MetaData;
