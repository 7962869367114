import React from "react";

import Icon from 'react-native-vector-icons/Ionicons';

import { colors } from '../constants/colors';
import { fonts } from '../constants/fonts'

function CategoryName({ title, id, description }) {
  return (
    <div id={id} style={{
        fontSize: 22,
        color: colors.text,
        paddingTop: 20,
        paddingBottom: 0,
        fontFamily: fonts.bold,
        paddingLeft: 30,
        paddingRight: 30,
      }}>
      <p style={{fontFamily: global.headerFont}}>{title}</p>
      {description ? <p style={{
        fontSize: 13,
        color: colors.textSecondary,
        marginTop: -10,
        paddingBottom: 5,
        fontFamily: fonts.regular,
       }}>{description}</p> : null }
    </div>
  )
}


export default CategoryName


//     <TouchableOpacity
//      onPress={onPress}
//      style={{ backgroundColor: '#242424', paddingVertical: 12, paddingHorizontal: 20, marginRight: 10, marginVertical: 20, borderRadius: 20}}>
//      <Text style={{color: colors.white, fontSize: 14 , fontFamily: fonts.bold }} adjustsFontSizeToFit allowFontScaling>{title}</Text>
//    </TouchableOpacity>
