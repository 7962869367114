import React, { useState } from "react";
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity
} from "react-native";

import Icon from 'react-native-vector-icons/Ionicons';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faSeedling } from '@fortawesome/free-solid-svg-icons/faSeedling'
import { faCarrot } from '@fortawesome/free-solid-svg-icons/faCarrot'
import { faWineGlass } from '@fortawesome/free-solid-svg-icons/faWineGlass'

import { colors } from '../constants/colors';
import { fonts } from '../constants/fonts';

class ItemVariantPicker extends React.Component {

  constructor(props) {
    super(props);
  }

  renderItemVariantPicker = (variants, selectedVariant, toggleSelectedVariant) => {

    return variants.map((variant, index) => {

      // Sorts allergens by name
      if (variant.allergens) {
        variant.allergens.sort()

        // Sorts Vegan, Vegetarian and Alchol to the top of the list to display first
        variant.allergens.filter(item => item !== "vegan");

        variant.allergens.includes('vegan') ? variant.allergens = ['vegan', ...variant.allergens.filter(item => item !== 'vegan')] : null
        variant.allergens.includes('vegetarian') ? variant.allergens = ['vegetarian', ...variant.allergens.filter(item => item !== 'vegetarian')] : null
        variant.allergens.includes('alcohol') ? variant.allergens = ['alcohol', ...variant.allergens.filter(item => item !== 'alcohol')] : null
      }

      return (
        <TouchableOpacity
          onPress={() => toggleSelectedVariant(variant)}
          style={selectedVariant.id == variant.id ? styles.variantSelected : styles.variant}
          disabled={variant.isAvailable != undefined && variant.isAvailable == false ? true : false}
          key={index}>
          <Text style={styles.name} adjustsFontSizeToFit allowFontScaling numberOfLines={1}>{variant.name}
          {variant.allergens != undefined ?
                <View style={styles.allergens}>
                {variant.allergens.map(a => {
                  return (
                    <View style={styles.allergenTag}>
                      {a ? a.includes('vegan') ? <FontAwesomeIcon style={styles.allergenTagIcon} size={12} icon={ faSeedling } /> : null : null}
                      {a? a.includes('vegetarian') ? <FontAwesomeIcon style={styles.allergenTagIcon} size={12} icon={ faCarrot } /> : null : null}
                      {a ? a.includes('alcohol') ? <FontAwesomeIcon style={styles.allergenTagIcon} size={12} icon={ faWineGlass } /> : null : null}
                      <Text style={styles.allergenTagText}>{a}</Text>
                    </View>
                  )
                })}
                </View>
          : null}
          </Text>

          {variant.isAvailable != undefined && variant.isAvailable == false ?
            <Text style={styles.unavailableLabel} adjustsFontSizeToFit allowFontScaling numOfLines={1}>Unavailable</Text>
          : null }

          <Text style={styles.price} adjustsFontSizeToFit allowFontScaling>{"£" + (+variant.price).toFixed(2)}</Text>
        </TouchableOpacity>
      )
    })
  }

  render() {
    return (
      <View style={styles.container}>
        {this.renderItemVariantPicker(this.props.variants, this.props.selectedVariant, this.props.toggleSelectedVariant)}
      </View>
    )
  }
}

export default ItemVariantPicker;

const styles = StyleSheet.create({
    container: {
      flex: 1,
      paddingTop: 20,
    },
    variant: {
      flexDirection: "row",
      borderWidth: 1,
      borderColor: colors.border,
      padding: 20,
      marginBottom: 15,
    },
    variantSelected: {
      flexDirection: "row",
      borderWidth: 1,
      borderColor: colors.border,
      backgroundColor: 'rgba(150,150,150, 0.1)',
      padding: 20,
      marginBottom: 15,
    },
    name: {
      flex: 1,
      color: colors.text,
      fontSize: 13,
      fontFamily: fonts.regular,

    },
    price: {
      color: colors.text,
      fontSize: 13,
      fontFamily: fonts.regular,
    },
    allergens: {
      paddingLeft: 5,
      flexDirection: "row",
      marginTop: -4,
            position: "absolute",
    },
    allergenTag: {
      backgroundColor: colors.secondary,
      minWidth: 40,
      paddingVertical: 5,
      paddingHorizontal: 10,
      flexDirection: "row",
      borderRadius: 20,
      marginRight: 5,
      opacity: 0.7
    },
    allergenTagText: {
      fontFamily: fonts.regular,
      color: colors.white,
      fontSize: 11,
      textTransform: "capitalize"
    },
    allergenTagIcon: {
      color: colors.white,
      paddingRight: 5,
      position: "relative",
      top: 1,
    },
    unavailableLabel: {
      backgroundColor: colors.border,
      color: colors.textSecondary,
      fontSize: 11,
      right: 70,
      borderRadius: 20,
      paddingVertical: 7,
      paddingHorizontal: 12,
      marginTop: -5,
      position: "absolute",
      fontFamily: fonts.regular,
    },
});
